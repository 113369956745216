"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFields = exports.CustomFieldsForm = void 0;
var react_1 = require("react");
var jsx_runtime_1 = require("react/jsx-runtime");
var react_2 = require("react");
var react_hook_form_1 = require("react-hook-form");
var yup_1 = require("@hookform/resolvers/yup");
var ds_ui_components_1 = require("@la/ds-ui-components");
var CheckboxGroupFormComponent_1 = require("./CheckboxGroupFormComponent/CheckboxGroupFormComponent");
var layout_1 = require("./utils/layout");
var validation_1 = require("./utils/validation");
var S = require("./CustomFieldsForm.styles");
function CustomFieldsForm(_a) {
    var _b;
    var _c = _a.columns, columns = _c === void 0 ? 2 : _c, formFields = _a.formFields, gap = _a.gap, id = _a.id, onSubmit = _a.onSubmit;
    var validation = (0, react_2.useMemo)(function () { return (0, validation_1.createValidationSchema)(id); }, [id]);
    var methods = (0, react_hook_form_1.useForm)({
        defaultValues: (_b = {},
            _b[id] = formFields,
            _b),
        resolver: (0, yup_1.yupResolver)(validation),
    });
    var isSubmitting = methods.formState.isSubmitting, handleSubmit = methods.handleSubmit;
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, __assign({}, methods, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Form, __assign({ id: id, noValidate: true, onSubmit: handleSubmit(function (values) {
                if (!isSubmitting) {
                    onSubmit(values);
                }
            }) }, { children: (0, jsx_runtime_1.jsx)(CustomFields, { columns: columns, gap: gap, name: id }) })) })));
}
exports.CustomFieldsForm = CustomFieldsForm;
/**
 * Renders a set of custom fields with two inputs per row.
 * @param name The name of the form field that represents the array of
 * fields. Should match the name used in the containing useForm() component.
 */
function CustomFields(_a) {
    var _b = _a.columns, columns = _b === void 0 ? 2 : _b, gap = _a.gap, name = _a.name, _c = _a.inputSize, inputSize = _c === void 0 ? 'medium' : _c;
    var _d = (0, react_hook_form_1.useFormContext)(), control = _d.control, _e = _d.formState, errors = _e.errors, submitCount = _e.submitCount, register = _d.register, setValue = _d.setValue, watch = _d.watch;
    var _f = (0, react_hook_form_1.useFieldArray)({
        control: control,
        name: name,
    }), fields = _f.fields, update = _f.update;
    var _g = (0, react_2.useState)({}), customFileErrors = _g[0], setCustomFileErrors = _g[1];
    var _h = (0, react_2.useState)([]), uploadedFiles = _h[0], setUploadedFiles = _h[1];
    var fieldGroups = (0, layout_1.chunkArray)(fields, columns);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: fieldGroups.map(function (fieldGroup, fieldGroupIndex) { return ((0, jsx_runtime_1.jsx)(S.InputGroupContainer, __assign({ "$gap": gap }, { children: fieldGroup.map(function (field, fieldIndex) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                // Multiply `fieldGroupIndex` by `columns` so that the resulting index corresponds
                // to the index of the field from the `useFieldArray`'s `fields` array
                var index = fieldGroupIndex * columns + fieldIndex;
                var id = field.id, fieldName = field.name, propertyDefinitionId = field.propertyDefinitionId, type = field.type, usageLevel = field.usageLevel;
                var error = (_c = (_b = (_a = errors === null || errors === void 0 ? void 0 : errors[name]) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.message;
                var required = usageLevel === 'REQUIRED';
                var hasFileError = uploadedFiles.find(function (fileId) { return fileId === id; })
                    ? false
                    : Boolean((_d = !!error) !== null && _d !== void 0 ? _d : customFileErrors[id]);
                switch (type) {
                    case 'TEXT_BOX':
                        return ((0, jsx_runtime_1.jsx)(S.TextInputContainer, __assign({ "$columnSpan": columns === 1 ? 2 : 1 }, { children: (0, react_1.createElement)(ds_ui_components_1.TextInput, __assign({}, register("".concat(name, ".").concat(index, ".value")), { errorMessage: error === null || error === void 0 ? void 0 : error.toString(), hasError: !!error, key: id, id: id, label: fieldName, required: required, size: inputSize, value: watch("".concat(name, ".").concat(index, ".value")) })) }), propertyDefinitionId));
                    case 'NUMERIC':
                        var _k = register("".concat(name, ".").concat(index, ".value")), _min = _k.min, _max = _k.max, numericRegisterProps = __rest(_k, ["min", "max"]);
                        return ((0, jsx_runtime_1.jsx)(S.TextInputContainer, __assign({ "$columnSpan": columns === 1 ? 2 : 1 }, { children: (0, react_1.createElement)(ds_ui_components_1.TextInput, __assign({}, numericRegisterProps, { errorMessage: error === null || error === void 0 ? void 0 : error.toString(), hasError: !!error, key: id, id: id, label: fieldName, onChange: function (e) {
                                    setValue("".concat(name, ".").concat(index, ".value"), e.target.value.toString());
                                }, required: required, size: inputSize, value: watch("".concat(name, ".").concat(index, ".value")), type: "number", forceLeftAlignment: true })) }), propertyDefinitionId));
                    case 'PICK_LIST':
                        var items_1 = field.items;
                        var currentItemId_1 = watch("".concat(name, ".").concat(index, ".value"));
                        var currentValue = (_e = items_1.find(function (item) { return item.itemId === currentItemId_1; })) === null || _e === void 0 ? void 0 : _e.value;
                        return ((0, jsx_runtime_1.jsx)(S.InputContainer, __assign({ "$columnSpan": columns === 1 ? 2 : 1 }, { children: (0, react_1.createElement)(ds_ui_components_1.Select, __assign({}, register("".concat(name, ".").concat(index, ".value")), { errorMessage: error === null || error === void 0 ? void 0 : error.toString(), hasError: !!error, key: id, id: id, label: fieldName, onChange: function (value) {
                                    var _a;
                                    var itemId = (_a = items_1.find(function (item) { return item.value === value || item.name === value; })) === null || _a === void 0 ? void 0 : _a.itemId;
                                    setValue("".concat(name, ".").concat(index, ".value"), itemId, {
                                        shouldValidate: submitCount > 0,
                                    });
                                }, options: items_1.map(function (item) {
                                    var _a;
                                    return ({
                                        label: item.name,
                                        value: (_a = item.value) !== null && _a !== void 0 ? _a : item.name,
                                    });
                                }), placeholder: "Select an option", required: required, size: inputSize, value: currentValue })) }), propertyDefinitionId));
                    case 'TEXT_AREA':
                        return ((0, jsx_runtime_1.jsx)(S.InputContainer, __assign({ "$columnSpan": columns === 1 ? 2 : 1 }, { children: (0, react_1.createElement)(ds_ui_components_1.TextArea, __assign({}, register("".concat(name, ".").concat(index, ".value")), { errorMessage: error === null || error === void 0 ? void 0 : error.toString(), hasError: !!error, key: id, id: id, label: fieldName, required: required, size: inputSize, value: watch("".concat(name, ".").concat(index, ".value")) })) }), propertyDefinitionId));
                    case 'MULTIPLE_CHECKBOXES':
                        var _l = register("".concat(name, ".").concat(index, ".value")), _ref = _l.ref, checkboxesRegisterProps = __rest(_l, ["ref"]);
                        var options = field.items.map(function (item) { return ({
                            label: item.name,
                            value: item.itemId.toString(),
                            selected: false,
                        }); });
                        return ((0, jsx_runtime_1.jsxs)(S.InputContainer, __assign({ "$columnSpan": columns === 1 ? 2 : 1 }, { children: [(0, react_1.createElement)(CheckboxGroupFormComponent_1.CheckboxGroupFormComponent, __assign({}, checkboxesRegisterProps, { checkboxGroupData: {
                                        hasError: !!error,
                                        isRequired: required,
                                        name: fieldName,
                                        label: fieldName,
                                        value: (_g = (_f = field.value) === null || _f === void 0 ? void 0 : _f.join(',')) !== null && _g !== void 0 ? _g : '',
                                        options: options,
                                        type: 'MULTIPLE_CHECKBOXES',
                                    }, key: id, onChangeValue: function (value) {
                                        // Need to use `update` instead of `setValue` here because
                                        // update performs an unmount and remount and we need it
                                        // in order to appropriately update the value.
                                        update(index, __assign(__assign({}, field), { value: value.split(',').map(function (v) { return parseInt(v); }) }));
                                    }, value: (_h = watch("".concat(name, ".").concat(index, ".value"))) === null || _h === void 0 ? void 0 : _h.join(',') })), error ? ((0, jsx_runtime_1.jsx)(ds_ui_components_1.InputErrorMessage, { children: error.toString() })) : null] }), propertyDefinitionId));
                    case 'FILE_UPLOAD':
                        return ((0, jsx_runtime_1.jsxs)(S.InputContainer, __assign({ "$columnSpan": columns === 1 ? 2 : 1 }, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.InputLabel, __assign({ htmlFor: id, inputState: hasFileError ? 'error' : 'default', required: required }, { children: fieldName })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.FileInput, { accept: ['.jpg', '.png', '.jpeg', '.pdf'], alignment: "center", errorMessage: (_j = customFileErrors[id]) !== null && _j !== void 0 ? _j : error === null || error === void 0 ? void 0 : error.toString(), hasError: hasFileError, id: id, infoMessage: "Max upload size is 500kb. Supported file types are .jpg, .png, and .pdf.", multiple: false, onDismiss: function () {
                                        setValue("".concat(name, ".").concat(index, ".value"), undefined);
                                        setUploadedFiles(function (prev) {
                                            return prev.filter(function (fileId) { return fileId !== id; });
                                        });
                                    }, onError: function (_a) {
                                        var message = _a.message;
                                        return setCustomFileErrors(function (prev) {
                                            var _a;
                                            return (__assign(__assign({}, prev), (_a = {}, _a[id] = message, _a)));
                                        });
                                    }, initialValue: fields
                                        .filter(function (field) {
                                        return field.type === 'FILE_UPLOAD' &&
                                            field.value &&
                                            field.id === id;
                                    })
                                        .map(function (field) {
                                        return field.value.name;
                                    }), onUpload: function (file) {
                                        if (file) {
                                            setValue("".concat(name, ".").concat(index, ".value"), file);
                                            setUploadedFiles(function (prev) { return prev.concat(id); });
                                        }
                                    } }, id)] }), propertyDefinitionId));
                    default:
                        return null;
                }
            }) }), "".concat(name, "-").concat(fieldGroupIndex))); }) }));
}
exports.CustomFields = CustomFields;
