import {
  createContext,
  useState,
  useContext,
  PropsWithChildren,
  useReducer,
} from 'react';
import {
  BillingSummaryRequestItem,
  PaymentMethod,
  PaymentMethodType,
} from 'domains/Checkout/Checkout.types';
import { isSingleUseCard } from 'domains/Checkout/PaymentMethodCard/PaymentMethodCard';

type CheckoutFieldErrorStates = {
  hasPaymentMethodError?: boolean;
  hasPaymentPolicyError?: boolean;
};

export type CheckoutInfo = {
  cartHasAutopayPaymentOption: boolean;
  checkoutErrorsAreVisible: boolean;
  checkoutFieldErrorStates: CheckoutFieldErrorStates;
  hasAgreedToAutopay: boolean;
  hasAutopayPaymentOptionSelected: boolean;
  hasAgreedToPaymentPolicy: boolean;
  hasPaymentWaiver: boolean;
  selectedPaymentMethod: PaymentMethod;
  selectedPaymentOptions?: BillingSummaryRequestItem[];
  submissionErrorsAreVisible: boolean;
  updateCartHasAutopayPaymentOption: (hasAutopayPaymentOption: boolean) => void;
  updateCheckoutErrorsAreVisible: () => void;
  updateHasAgreedToAutopay: (checked: boolean) => void;
  updateHasAutopayPaymentOptionSelected: (selected: boolean) => void;
  updateHasAgreedToPaymentPolicy: (checked: boolean) => void;
  updateHasPaymentWaiver: (hasPaymentWaiver: boolean) => void;
  updateSelectedPaymentMethod: (paymentMethod: PaymentMethod) => void;
  updateSelectedPaymentOptions: (
    paymentTerms: BillingSummaryRequestItem[]
  ) => void;
  updateSubmissionErrorsAreVisible: (displayError: boolean) => void;
  updatePaymentMethodErrors: (displayError: boolean) => void;
  updatePaymentWaiverErrors: (displayError: boolean) => void;
};

const checkoutFieldErrorStatesDefaultValues: CheckoutFieldErrorStates = {
  hasPaymentMethodError: undefined,
  hasPaymentPolicyError: undefined,
};

export const checkoutInfoDefaultValues: CheckoutInfo = {
  cartHasAutopayPaymentOption: false,
  checkoutErrorsAreVisible: false,
  checkoutFieldErrorStates: checkoutFieldErrorStatesDefaultValues,
  hasAgreedToAutopay: false,
  hasAutopayPaymentOptionSelected: false,
  hasAgreedToPaymentPolicy: false,
  hasPaymentWaiver: false,
  selectedPaymentMethod: null,
  selectedPaymentOptions: undefined,
  submissionErrorsAreVisible: false,
  updateCartHasAutopayPaymentOption: (hasAutopayPaymentOption: boolean) => {},
  updateCheckoutErrorsAreVisible: () => {},
  updateHasAgreedToAutopay: (checked: boolean) => {},
  updateHasAutopayPaymentOptionSelected: (selected: boolean) => {},
  updateHasAgreedToPaymentPolicy: (checked: boolean) => {},
  updateHasPaymentWaiver: (hasPaymentWaiver: boolean) => {},
  updateSelectedPaymentMethod: (paymentMethod: PaymentMethod | null) => {},
  updateSelectedPaymentOptions: (
    paymentOptions: BillingSummaryRequestItem[]
  ) => {},
  updateSubmissionErrorsAreVisible: (displayError: boolean) => {},
  updatePaymentMethodErrors: (displayError: boolean) => {},
  updatePaymentWaiverErrors: (displayError: boolean) => {},
};

function checkoutErrorsReducer(
  checkoutFieldErrorStates: CheckoutFieldErrorStates,
  { type, hasError }: { type: 'method' | 'policy'; hasError: boolean }
) {
  switch (type) {
    case 'method':
      return {
        ...checkoutFieldErrorStates,
        hasPaymentMethodError: hasError,
      };
    case 'policy':
      return {
        ...checkoutFieldErrorStates,
        hasPaymentPolicyError: hasError,
      };
    default:
      return checkoutFieldErrorStates;
  }
}

const CheckoutInfoContext = createContext<CheckoutInfo>(
  checkoutInfoDefaultValues
);

const CheckoutInfoProvider = ({ children }: PropsWithChildren) => {
  const [cartHasAutopayPaymentOption, setCartHasAutopayPaymentOption] =
    useState<boolean>(checkoutInfoDefaultValues.cartHasAutopayPaymentOption);
  const [checkoutErrorsAreVisible, setCheckoutErrorsAreVisible] =
    useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(
      checkoutInfoDefaultValues.selectedPaymentMethod
    );
  const [selectedPaymentTerms, setSelectedPaymentTerms] = useState<
    BillingSummaryRequestItem[] | undefined
  >(checkoutInfoDefaultValues.selectedPaymentOptions);
  const [hasPaymentWaiver, setHasPaymentWaiver] = useState<boolean>(
    checkoutInfoDefaultValues.hasPaymentWaiver
  );
  const [submissionErrorsAreVisible, setSubmissionErrorsAreVisible] =
    useState<boolean>(checkoutInfoDefaultValues.submissionErrorsAreVisible);
  const [hasAutopayPaymentOptionSelected, setHasAutopayPaymentOptionSelected] =
    useState<boolean>(
      checkoutInfoDefaultValues.hasAutopayPaymentOptionSelected
    );
  const [hasAgreedToAutopay, setHasAgreedToAutopay] = useState<boolean>(
    checkoutInfoDefaultValues.hasAgreedToAutopay
  );
  const [hasAgreedToPaymentPolicy, setHasAgreedToPaymentPolicy] =
    useState<boolean>(checkoutInfoDefaultValues.hasAgreedToPaymentPolicy);
  const [checkoutFieldErrorStates, dispatch] = useReducer(
    checkoutErrorsReducer,
    checkoutInfoDefaultValues.checkoutFieldErrorStates
  );

  function updateCartHasAutopayPaymentOption(hasAutopayPaymentOption: boolean) {
    setCartHasAutopayPaymentOption(hasAutopayPaymentOption);
  }

  function updateCheckoutErrorsAreVisible() {
    setCheckoutErrorsAreVisible(true);
  }

  function updateHasAgreedToAutopay(hasAgreed: boolean) {
    setHasAgreedToAutopay(hasAgreed);
    if (hasAutopayPaymentOptionSelected) {
      updatePaymentMethodErrors(!hasAgreed);
    } else {
      updatePaymentMethodErrors(false);
    }
  }

  function updateHasAutopayPaymentOptionSelected(selected: boolean) {
    setHasAutopayPaymentOptionSelected(selected);
    updatePaymentMethodErrors(
      selectedPaymentMethod
        ? !selectedPaymentMethod.paymentMethodId && selected
        : false
    );
  }

  function updateHasAgreedToPaymentPolicy(hasAgreed: boolean) {
    setHasAgreedToPaymentPolicy(hasAgreed);
    updatePaymentWaiverErrors(!hasAgreed);
  }

  function updateSelectedPaymentMethod(paymentMethod: PaymentMethod) {
    setSelectedPaymentMethod(paymentMethod);
    updatePaymentMethodErrors(
      !paymentMethod ||
        (!paymentMethod.paymentMethodId && hasAutopayPaymentOptionSelected)
    );
  }

  function updateSelectedPaymentTerms(
    paymentTerms: BillingSummaryRequestItem[]
  ) {
    setSelectedPaymentTerms(paymentTerms);
  }

  function updatePaymentMethodErrors(displayError: boolean) {
    dispatch({ type: 'method', hasError: displayError });
  }

  function updateHasPaymentWaiver(hasWaiver: boolean) {
    setHasPaymentWaiver(hasWaiver);
  }

  function updatePaymentWaiverErrors(displayError: boolean) {
    dispatch({ type: 'policy', hasError: displayError });
  }

  function updateSubmissionErrorsAreVisible(displayError: boolean) {
    setSubmissionErrorsAreVisible(displayError);
  }

  return (
    <CheckoutInfoContext.Provider
      value={{
        cartHasAutopayPaymentOption: cartHasAutopayPaymentOption,
        checkoutErrorsAreVisible: checkoutErrorsAreVisible,
        checkoutFieldErrorStates: checkoutFieldErrorStates,
        hasAgreedToAutopay: hasAgreedToAutopay,
        hasAutopayPaymentOptionSelected: hasAutopayPaymentOptionSelected,
        hasAgreedToPaymentPolicy: hasAgreedToPaymentPolicy,
        hasPaymentWaiver: hasPaymentWaiver,
        selectedPaymentMethod: selectedPaymentMethod,
        selectedPaymentOptions: selectedPaymentTerms,
        submissionErrorsAreVisible: submissionErrorsAreVisible,
        updateCartHasAutopayPaymentOption: updateCartHasAutopayPaymentOption,
        updateCheckoutErrorsAreVisible: updateCheckoutErrorsAreVisible,
        updateHasAgreedToAutopay: updateHasAgreedToAutopay,
        updateHasAutopayPaymentOptionSelected:
          updateHasAutopayPaymentOptionSelected,
        updateHasAgreedToPaymentPolicy: updateHasAgreedToPaymentPolicy,
        updateHasPaymentWaiver: updateHasPaymentWaiver,
        updateSelectedPaymentMethod: updateSelectedPaymentMethod,
        updateSelectedPaymentOptions: updateSelectedPaymentTerms,
        updateSubmissionErrorsAreVisible: updateSubmissionErrorsAreVisible,
        updatePaymentMethodErrors: updatePaymentMethodErrors,
        updatePaymentWaiverErrors: updatePaymentWaiverErrors,
      }}
    >
      {children}
    </CheckoutInfoContext.Provider>
  );
};

const useCheckoutInfo = () => {
  const {
    cartHasAutopayPaymentOption,
    hasAgreedToAutopay,
    hasAutopayPaymentOptionSelected,
    hasAgreedToPaymentPolicy,
    hasPaymentWaiver,
    selectedPaymentMethod,
    selectedPaymentOptions,
    submissionErrorsAreVisible,
    updateCartHasAutopayPaymentOption,
    updateCheckoutErrorsAreVisible,
    updateHasPaymentWaiver,
    updateHasAgreedToAutopay,
    updateHasAutopayPaymentOptionSelected,
    updateHasAgreedToPaymentPolicy,
    updateSelectedPaymentMethod,
    updateSelectedPaymentOptions,
    updatePaymentMethodErrors,
    updatePaymentWaiverErrors,
    updateSubmissionErrorsAreVisible,
    checkoutFieldErrorStates,
    checkoutErrorsAreVisible,
  } = useContext<CheckoutInfo>(CheckoutInfoContext);

  const selectedPaymentMethodType: PaymentMethodType =
    selectedPaymentMethod?.paymentType ?? null;

  const handleHasAgreedToPaymentPolicyChange = (checked: boolean) => {
    updateHasAgreedToPaymentPolicy(checked);
  };

  const handleSelectedPaymentMethodChange = (
    paymentMethod: PaymentMethod | null
  ) => {
    if (paymentMethod !== selectedPaymentMethod) {
      updateSelectedPaymentMethod(paymentMethod);
      updatePaymentMethodErrors(!paymentMethod);
    }
  };

  function validatePaymentMethod(dueToday?: string) {
    const autopayAgreementValid = hasAutopayPaymentOptionSelected
      ? hasAgreedToAutopay && !isSingleUseCard(selectedPaymentMethod)
      : true;
    const paymentMethodNotNeeded: boolean =
      !!dueToday &&
      parseFloat(dueToday) === 0 &&
      !hasAutopayPaymentOptionSelected;
    return (
      (!!selectedPaymentMethod && autopayAgreementValid) ||
      paymentMethodNotNeeded
    );
  }

  function validatePaymentWaiver() {
    if (hasPaymentWaiver) {
      updateHasAgreedToPaymentPolicy(hasAgreedToPaymentPolicy);
      return hasAgreedToPaymentPolicy;
    }

    return true;
  }

  function validateCheckout(dueToday?: string): {
    methodIsValid: boolean;
    waiverIsValid: boolean;
  } {
    const methodIsValid = validatePaymentMethod(dueToday);
    const waiverIsValid = validatePaymentWaiver();
    return { methodIsValid, waiverIsValid };
  }

  function updateErrorStates(
    displayMethodError: boolean,
    displayWaiverError: boolean
  ) {
    updatePaymentMethodErrors(displayMethodError);
    updatePaymentWaiverErrors(displayWaiverError);
  }

  return {
    validateCheckout: validateCheckout,
    updateErrorStates: updateErrorStates,
    cartHasAutopayPaymentOption: cartHasAutopayPaymentOption,
    checkoutFieldErrorStates: checkoutFieldErrorStates,
    checkoutErrorsAreVisible: checkoutErrorsAreVisible,
    hasAgreedToAutopay: hasAgreedToAutopay,
    hasAutopayPaymentOptionSelected: hasAutopayPaymentOptionSelected,
    hasAgreedToPaymentPolicy: hasAgreedToPaymentPolicy,
    hasPaymentWaiver: hasPaymentWaiver,
    selectedPaymentMethod: selectedPaymentMethod,
    selectedPaymentMethodType: selectedPaymentMethodType,
    selectedPaymentOptions: selectedPaymentOptions,
    submissionErrorsAreVisible: submissionErrorsAreVisible,
    updateCartHasAutopayPaymentOption: updateCartHasAutopayPaymentOption,
    updateCheckoutErrorsAreVisible: updateCheckoutErrorsAreVisible,
    updateHasAgreedToAutopay: updateHasAgreedToAutopay,
    updateHasAutopayPaymentOptionSelected:
      updateHasAutopayPaymentOptionSelected,
    updateHasAgreedToPaymentPolicy: updateHasAgreedToPaymentPolicy,
    updateHasPaymentWaiver: updateHasPaymentWaiver,
    handleHasAgreedToPaymentPolicyChange: handleHasAgreedToPaymentPolicyChange,
    updateSelectedPaymentMethod: handleSelectedPaymentMethodChange,
    updateSelectedPaymentOptions: updateSelectedPaymentOptions,
    updateSubmissionErrorsAreVisible: updateSubmissionErrorsAreVisible,
  };
};

export { CheckoutInfoContext, CheckoutInfoProvider, useCheckoutInfo };
