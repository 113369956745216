import { PriceAdjustment } from '@la/types';
import { PaymentNetwork } from './PaymentMethodBadge/PaymentMethodBadgeConfig';

export type PaymentTerm = 'FULL' | 'PAYMENT_PLAN';

export type BillingSummaryRequestItems = {
  cartItemUuid: string;
  paymentTerm: PaymentTerm;
  paymentPlanId: string | null;
};

export type BillingSummaryData = {
  cartUuid: string;
  currency: string;
  dueToday: string;
  summary: BillingSummaryItem[];
  totalAmount: string;
  transactionFees: string;
};

export type BillingSummaryItem = {
  cartItemName: string;
  cartItemType: string;
  description: string;
  dueToday: string;
  paymentTerm: PaymentTerm;
  paymentTermLabel: string;
  totalAmount: string;
};

export type CartSummaryData = {
  cartItems: CartItemData[];
  cartUuid: string;
  currency: string;
  subtotal: string;
};

export type WaiverData = {
  content: string;
  name: string;
  programId: number;
  waiverId: number;
  waiverType: string;
};

export enum CartItemType {
  TournamentTeamRegistration = 'TOURNAMENT_TEAM_REGISTRATION',
}

export type CartItemData = {
  cartItemUuid: string;
  cartItemType: CartItemType;
  createdBy: number;
  createdOn: string;
  currency: string;
  editUrl: string;
  lastUpdatedBy: number;
  lastUpdatedOn: string;
  options: CartItemOptions;
  subtotal: string;
};

export type CartItemOptions = {
  program: CartItemOptionsProgram;
  subprograms: CartItemOptionsSubprograms;
};

export type CartItemOptionsProgram = {
  programId: number;
  programName: string;
  programType: string;
  programLogo?: string;
  isGroupedProgram: boolean;
  basePrice: string;
  currency: string;
  locationId?: number;
  locationName?: string;
  startDate: string;
  endDate: string;
  paymentPlanSummaries?: PaymentPlanSummary[];
  paymentRules?: PaymentRules;
};

export type CartItemHotelLink = {
  id: string;
  hotelLink?: string;
};

export type PaymentPlanSummary = {
  ngPaymentPlanId: string;
  name: string;
  autopayRequired: boolean;
  dueCheckout: boolean;
  installments: PaymentPlanSummaryInstallment[];
  priceAdjustment?: PriceAdjustment;
};

export type PaymentPlanSummaryInstallment = {
  percentage: string;
  installmentTotal: string;
  installmentDate: string;
  installmentOrder: number;
};

export type PaymentRules = {
  uuid: string;
  payInFull: boolean;
};

export type CartItemOptionsSubprograms = {
  label: string;
  details: CartItemOptionsSubprogramsDetail[];
};

export type CartItemOptionsSubprogramsDetail = {
  subprogramId: number;
  subprogramName: string;
  basePrice: string;
  currency: string;
  registrations: CartItemOptionsSubprogramsRegistration[];
};

export type CartItemOptionsSubprogramsRegistration = {
  registrationId: number;
  teamId: number;
  teamName: string;
};

export type PaymentOptionsItem = {
  itemId: string;
  payInFull?: boolean;
  paymentDetail: string;
  payLater?: boolean;
  paymentOption: string;
  paymentOptions: PaymentPlanSummary[];
  priceAdjustment?: PriceAdjustment;
  subTotal: string;
  tournamentName: string;
};

export type PaymentOptionsData = PaymentOptionsItem[];

export type SingleUseCard = {
  cardBrand: string;
  cardExpirationMonth: number | null;
  cardExpirationYear: number | null;
  cardId: string;
  cardLast4: string;
  cardType: 'SINGLE_USE';
  paymentType: 'CARD';
  tokenId: string;
  paymentMethodId?: number;
};

export type StoredBankAccount = {
  accountType: string;
  bankName: string;
  createdOn: string;
  isPrimaryPaymentOption: boolean;
  last4Digits: string;
  paymentType: 'ACH';
  storedBankAccountId: number;
  paymentMethodId: number;
};

export type StoredCreditCard = {
  cardType: 'STORED';
  createdOn: string;
  expirationDate: string;
  isPrimaryPaymentOption: boolean;
  last4Digits: string;
  paymentNetwork: PaymentNetwork;
  paymentType: 'CARD';
  storedCreditCardId: number;
  paymentMethodId: number;
};

export type PaymentMethodInfo = {
  storedBankAccounts?: StoredBankAccount[];
  storedCreditCards?: StoredCreditCard[];
  paymentOptionsCount: number;
};

export type AddCreditCardRequestData = {
  autoBilled: boolean;
  last4Digits: string;
  paymentNetwork: string;
  primaryMethod: boolean;
  stripeToken: string;
};

export type StoredPaymentMethod = StoredBankAccount | StoredCreditCard;

export type PaymentMethod = SingleUseCard | StoredPaymentMethod | null;

export type PaymentMethodType = 'ACH' | 'CARD' | null;

export type BillingSummaryRequestItem = {
  autopayRequired: boolean;
  cartItemUuid: string;
  paymentTerm: PaymentTerm;
  paymentPlanId?: string;
  payLater?: boolean;
  priceAdjustment?: PriceAdjustment;
};

export type StoredCreditCardResponseData = {
  storedCreditCard: {
    createdOn: string;
    creditCardTypeTitle: PaymentNetwork;
    expirationMonth: number;
    expirationYear: number;
    gatewayTitle: string;
    last4Digits: string;
    paymentGatewayAccountId: number;
    settings: {
      ccStorageId: string;
      isAutoBilled: boolean;
      isPrimary: boolean;
      isPrimaryPaymentOption: boolean;
    };
    siteId: string;
    storedCreditCardId: number;
    userId: number;
  };
};

export type ProcessPaymentBody = {
  paymentMethod: {
    type: PaymentMethodType;
    storedPaymentId: number | null;
    token: string | null;
  };
  paymentTerms: BillingSummaryRequestItems[];
  paymentWaiverId: string | null;
};
