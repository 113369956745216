import { FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import {
  ChildYouthAthleteAccess,
  FamilyYouthAthleteAccess,
} from '../YouthAthleteAccess.types';
import YouthAthleteSection from '../YouthAthleteSection/YouthAthleteSection';

interface YouthAthleteSectionListProps {
  youthAthletes: ChildYouthAthleteAccess[];
  errors: FieldErrors<FamilyYouthAthleteAccess>;
  watch: UseFormWatch<FamilyYouthAthleteAccess>;
  setValue: UseFormSetValue<FamilyYouthAthleteAccess>;
}

export default function YouthAthleteSectionList({
  youthAthletes,
  errors,
  watch,
  setValue,
}: YouthAthleteSectionListProps) {
  const youthAthleteElements = youthAthletes.map((youthAthlete, idx) => {
    const { firstName, lastName, userId, email } = youthAthlete;
    return (
      <YouthAthleteSection
        firstName={firstName}
        lastName={lastName}
        userId={userId}
        email={watch(`youthAthletes.${idx}.email`)}
        emailReadOnly={!!email} // true if email exists on component load
        emailErrorMsg={errors.youthAthletes?.[idx]?.email?.message}
        hasYouthAthletePermission={watch(
          `youthAthletes.${idx}.hasYouthAthletePermission`
        )}
        onChangeEmail={(value) => setValue(`youthAthletes.${idx}.email`, value)}
        onChangePermission={(value) =>
          setValue(`youthAthletes.${idx}.hasYouthAthletePermission`, value)
        }
        key={userId}
      />
    );
  });

  return <>{youthAthleteElements}</>;
}
