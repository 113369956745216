"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Registration = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var ds_ui_components_1 = require("@la/ds-ui-components");
var services_1 = require("@la/services");
var utilities_1 = require("@la/utilities");
var ErrorCard_1 = require("../../components/ErrorCard/ErrorCard");
var MainContent_1 = require("../../components/MainContent/MainContent");
var Stepper_1 = require("../../components/Stepper/Stepper");
var SubNavigation_1 = require("../../components/SubNavigation/SubNavigation");
var BaseRegistration_1 = require("./BaseRegistration");
var ReviewInvoices_1 = require("./ReviewInvoices/ReviewInvoices");
var SelectPayer_1 = require("./SelectPayer/SelectPayer");
var MCRegistrationSteps_1 = require("./TeamRegistration/MCRegistrationSteps");
var TeamRegistrationContext_1 = require("./TeamRegistration/TeamRegistrationContext");
var Wizard_1 = require("./Wizard/Wizard");
var WizardHeader_1 = require("./Wizard/components/WizardHeader/WizardHeader");
var constants_1 = require("./constants");
var S = require("./Registration.styles");
var Wizard_styles_1 = require("./Wizard/Wizard.styles");
var BaseRegistration = function (_a) {
    var _b = _a.isMC, isMC = _b === void 0 ? false : _b, _c = _a.includeRegistrationTriageFix, includeRegistrationTriageFix = _c === void 0 ? false : _c;
    var _d = (0, react_router_dom_1.useParams)(), tournamentId = _d.tournamentId, siteId = _d.siteId;
    var _e = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext), state = _e.state, setWizardState = _e.setWizardState;
    var _f = (0, react_1.useState)(1), step = _f[0], setStep = _f[1];
    var _g = (0, react_1.useState)(false), touched = _g[0], setTouched = _g[1];
    var _h = (0, react_1.useState)(false), saveDivisions = _h[0], setSaveDivisions = _h[1];
    var _j = (0, react_1.useState)(false), isCreatingInvoices = _j[0], setIsCreatingInvoices = _j[1];
    if (!tournamentId) {
        throw new Error('The tournament ID is not defined');
    }
    var _k = (0, services_1.useGetTournamentQuery)({
        siteDomain: 'svssc',
        tournamentId: tournamentId,
        siteId: siteId,
    }), tournament = _k.data, isTournamentLoading = _k.isLoading, tournamentError = _k.error;
    var _l = (0, services_1.useGetCountriesAndAdministrativeDivisionsQuery)(), isCountriesLoading = _l.isLoading, hasCountriesError = _l.isError;
    if (isTournamentLoading || isCountriesLoading) {
        return ((0, jsx_runtime_1.jsx)(MainContent_1.MainContentCenter, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Loader, { description: "We are gathering available divisions...", loading: true }) }));
    }
    if (isCreatingInvoices) {
        return ((0, jsx_runtime_1.jsx)(MainContent_1.MainContentCenter, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Loader, { description: "Creating invoices...", loading: true }) }));
    }
    if (tournamentError || hasCountriesError) {
        return ((0, jsx_runtime_1.jsx)(MainContent_1.MainContent, { children: (0, jsx_runtime_1.jsx)(Wizard_styles_1.WizardContent, { children: (0, jsx_runtime_1.jsx)(S.RegistrationErrorContainer, { children: (0, jsx_runtime_1.jsx)(ErrorCard_1.ErrorCard, { message: constants_1.API_ERROR_MESSAGE }) }) }) }));
    }
    var registrationEndDate = tournament.registrationEndDate, registrationStartDate = tournament.registrationStartDate, timeZone = tournament.timeZone;
    if (tournament.divisions.length === 0 ||
        (registrationStartDate &&
            registrationEndDate &&
            !(0, utilities_1.isWithinDateRange)(registrationStartDate, registrationEndDate, timeZone))) {
        throw new Error(constants_1.TOURNAMENT_NOT_FOUND_ERROR_MESSAGE);
    }
    var hasRegistrationsToEdit = !!(tournament === null || tournament === void 0 ? void 0 : tournament.divisions.find(function (division) {
        var _a;
        return ((_a = division.registrationsForUser) === null || _a === void 0 ? void 0 : _a.team) &&
            division.registrationsForUser.team.find(function (registration) { return registration.registrationStatus === 'CREATED'; }) &&
            division.registrationsForUser.team.find(function (registration) { var _a; return registration.registeringUserId === ((_a = state.payer) === null || _a === void 0 ? void 0 : _a.id.toString()); });
    }));
    var renderSteps = function () {
        switch (step) {
            case 1:
                return (0, jsx_runtime_1.jsx)(SelectPayer_1.SelectPayer, {});
            case 2:
                return ((0, jsx_runtime_1.jsx)(BaseRegistration_1.BaseWizard, { saveDivisions: saveDivisions, stepForward: stepForward, setWizardState: setWizardState, includeRegistrationTriageFix: includeRegistrationTriageFix }));
            case 3:
                return (0, jsx_runtime_1.jsx)(ReviewInvoices_1.ReviewInvoices, {});
        }
    };
    var stepForward = function () {
        setStep(function (step) {
            if (step < 3) {
                return step + 1;
            }
            return step;
        });
    };
    var renderWizard = function () {
        if (!isMC) {
            return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: hasRegistrationsToEdit ? ((0, jsx_runtime_1.jsx)(Wizard_1.Wizard, { hasRegistrationsToEdit: hasRegistrationsToEdit, sectionTitle: "Edit your divisions and teams", submitButtonText: "Update cart", submitUnsavedChangesErrorMessage: "Registrations were not updated in the cart. Unsaved changes must be saved or discarded.", tournament: tournament, saveDivisions: saveDivisions, stepForward: stepForward, includeRegistrationTriageFix: includeRegistrationTriageFix })) : ((0, jsx_runtime_1.jsx)(Wizard_1.Wizard, { hasRegistrationsToEdit: hasRegistrationsToEdit, sectionTitle: "Add your divisions and teams", submitButtonText: "Add to cart", submitUnsavedChangesErrorMessage: "Registrations were not added to the cart. Unsaved changes must be saved or discarded.", tournament: tournament, saveDivisions: saveDivisions, stepForward: stepForward, includeRegistrationTriageFix: includeRegistrationTriageFix })) }));
        }
        var checkForErrors = function () {
            switch (step) {
                case 1:
                    return !state.payer && touched;
                case 2:
                    return false;
                default:
                    return false;
            }
        };
        var hasError = checkForErrors();
        var sendRegistrationsToWorkflow = function () { return __awaiter(void 0, void 0, void 0, function () {
            var numberOfRegisteredTeams;
            return __generator(this, function (_a) {
                numberOfRegisteredTeams = 0;
                Object.values(state.wizardState.divisionCardsState).forEach(function (divisionState) {
                    if (divisionState.value === 'saved' && divisionState.savedTeams) {
                        numberOfRegisteredTeams += divisionState.savedTeams.length;
                    }
                });
                setIsCreatingInvoices(true);
                (0, services_1.sendRegistrationsForInvoice)({ state: state, siteId: siteId })
                    .then(function () {
                    window.location.assign("https://".concat(window.location.host, "/console/sites/").concat(siteId, "/tournaments/").concat(tournament.id, "?registeredTeams=").concat(numberOfRegisteredTeams));
                })
                    .catch(function (err) {
                    setIsCreatingInvoices(false);
                    console.error(err);
                });
                return [2 /*return*/];
            });
        }); };
        var handleNextClick = function () {
            switch (step) {
                case 1: {
                    setTouched(true);
                    if (state.payer) {
                        stepForward();
                    }
                    break;
                }
                case 2: {
                    setSaveDivisions(true);
                    break;
                }
                case 3: {
                    sendRegistrationsToWorkflow();
                }
            }
        };
        var onBackClick = function () {
            switch (step) {
                case 1: {
                    break;
                }
                case 2: {
                    setSaveDivisions(false);
                    onBackwards();
                    break;
                }
                case 3: {
                    setSaveDivisions(false);
                    onBackwards();
                    break;
                }
            }
        };
        var onBackwards = function () {
            setStep(function (step) {
                if (step > 1) {
                    return step - 1;
                }
                return step;
            });
        };
        return ((0, jsx_runtime_1.jsx)(Stepper_1.Stepper, __assign({ currentStep: MCRegistrationSteps_1.MCStepTitles[step], error: hasError, form: {}, handleNextClick: handleNextClick, numberOfTotalSteps: 3, onBackClick: onBackClick, showDrawer: true, stepNumber: step, showSteps: true, type: "button", isMC: true }, { children: renderSteps() })));
    };
    return ((0, jsx_runtime_1.jsxs)(MainContent_1.MainContent, { children: [(0, jsx_runtime_1.jsx)(WizardHeader_1.WizardHeader, { isMC: isMC, tournament: tournament }), (0, jsx_runtime_1.jsx)(SubNavigation_1.SubNavigation, { programIdFromProps: parseInt(tournamentId) }), renderWizard()] }));
};
// Here we will fork MP registration and MC registration components
var Registration = function (props) { return ((0, jsx_runtime_1.jsx)(services_1.Provider, __assign({ store: services_1.store }, { children: (0, jsx_runtime_1.jsx)(BaseRegistration, __assign({}, props)) }))); };
exports.Registration = Registration;
