import { DateTime as LuxonDateTime } from 'luxon';
import * as yup from 'yup';
import { SelectOption } from '@la/ds-ui-components';
import { isGameActivity } from '../ActivityForm';
import {
  Activity,
  ActivityRecurrenceFrequencyUnitEnum,
  ActivityState,
  ActivityTeam,
  ActivityType,
  AllDayActivityType,
  CreateActivityRequestBody,
  CreateRecurringActivityRequestBody,
  DateTime,
  DayOfWeek,
  HomeAway,
  Location,
  PrimaryStaff,
  Team,
  TeamRepresentative,
  UpdateActivityRequestBody,
  UpdateRecurringActivityRequestBody,
} from '../openapi-client/models';
import { TeamPatch } from '../openapi-client/models/team-patch';
import { formatDataForSelectOption } from './select';

export const REQUEST_DELAY = 3000;

export type Frequency = 'does_not_repeat' | 'custom';

type ActivityGameTypes =
  | ActivityType.GameConsolation
  | ActivityType.GameFriendly
  | ActivityType.GameLeague
  | ActivityType.GamePlayoff
  | ActivityType.GameSeason
  | ActivityType.GameScrimmage
  | ActivityType.GameTournament
  | ActivityType.GameFinal;

export type ActivityFormBaseData = {
  activityType: ActivityType | AllDayActivityType;
  awayTeam?: string;
  gameType?: ActivityType;
  externalTeam?: string;
  allDay: boolean;
  subProgram: string;
  team: string;
  subLocation?: string;
  arrival?: string;
  notes?: string;
  title?: string;
  hasSubLocations: boolean;
  hasScheduleConflict?: boolean;
};

export type ActivityFormAllDayActivity = ActivityFormBaseData & {
  location: string;
  startDate: string;
  endDate: string;
  frequency: Frequency;
  startTime?: string;
  endTime?: string;
};

export type NonRecurringActivity = ActivityFormBaseData & {
  location?: string;
  startDate?: string;
  endDate?: string;
  frequency: Frequency;
  startTime?: string;
  endTime?: string;
};

export type RecurringActivity = ActivityFormBaseData & {
  location: string;
  startDate: string;
  endDate: string;
  frequency: ActivityRecurrenceFrequencyUnitEnum;
  startTime: string;
  endTime: string;
};

export type ActivityGame = ActivityFormBaseData & {
  awayTeam: string;
  gameType: ActivityGameTypes;
  location?: string;
  startDate?: string;
  endDate?: string;
  frequency: Frequency;
  startTime?: string;
  endTime?: string;
};

export type RecurringGameActivity = ActivityFormBaseData & {
  awayTeam: string;
  gameType: ActivityGameTypes;
  location: string;
  startDate: string;
  endDate: string;
  frequency: ActivityRecurrenceFrequencyUnitEnum;
  startTime: string;
  endTime: string;
};

export type ActivityFormData =
  | NonRecurringActivity
  | RecurringActivity
  | ActivityFormAllDayActivity
  | ActivityGame
  | RecurringGameActivity;

export const gameTypeOptions: SelectOption[] = [
  {
    value: ActivityType.GameConsolation,
    label: 'Consolation',
  },
  {
    value: ActivityType.GameFinal,
    label: 'Final',
  },
  {
    value: ActivityType.GameFriendly,
    label: 'Friendly',
  },
  {
    value: ActivityType.GameLeague,
    label: 'League',
  },
  {
    value: ActivityType.GamePlayoff,
    label: 'Playoff',
  },
  {
    value: ActivityType.GameSeason,
    label: 'Regular Season',
  },
  {
    value: ActivityType.GameScrimmage,
    label: 'Scrimmage',
  },
  {
    value: ActivityType.GameTournament,
    label: 'Tournament',
  },
];

export const frequencyOptions: SelectOption[] = [
  {
    value: 'does_not_repeat',
    label: 'Does not repeat',
  },
  {
    value: ActivityRecurrenceFrequencyUnitEnum.Day,
    label: 'Daily',
  },
  {
    value: ActivityRecurrenceFrequencyUnitEnum.Week,
    label: 'Weekly',
  },
  {
    value: ActivityRecurrenceFrequencyUnitEnum.Month,
    label: 'Monthly',
  },
];

export function getSubLocationsByLocationId(
  locations: Location[],
  locationId: string
) {
  if (!locationId) {
    return [];
  }

  const selectedLocationId = parseInt(locationId, 10);

  const sublocations = locations
    .filter((location) => location.id === selectedLocationId)
    .flatMap((location) =>
      location?.subLocations ? location.subLocations : []
    );

  if (sublocations.length) {
    const associatedSublocations = sublocations.filter(
      (sublocation) => sublocation.associated
    );
    return formatDataForSelectOption(associatedSublocations, 'name', 'id');
  }

  return [];
}

/**
 * Formats data for Activity Type Options
 */

export function formatDataForActivityTypeSelect(
  activityType?: ActivityType | AllDayActivityType
): SelectOption[] {
  const activityGameValue =
    activityType && isGameActivity(activityType)
      ? activityType
      : ActivityType.GameConsolation; //default to game_consolation when creating activity

  return [
    {
      value: activityGameValue,
      label: 'Game',
    },
    {
      value: ActivityType.EventCamp,
      label: 'Camp',
    },
    {
      value: ActivityType.EventClass,
      label: 'Class',
    },
    {
      value: ActivityType.EventMeeting,
      label: 'Meeting',
    },
    {
      value: ActivityType.EventOther,
      label: 'Other',
    },
    {
      value: ActivityType.EventPractice,
      label: 'Practice',
    },
    {
      value: ActivityType.EventTraining,
      label: 'Training',
    },
    {
      value: ActivityType.EventTournament,
      label: 'Tournament',
    },
  ];
}

/**
 * Formats data for the SelectPopover Team/Primary Staff component
 * Also sorts data to display alphabetically
 */
export type TeamPrimaryStaffSelectOptions = {
  label: string;
  value: string;
  primaryStaffName?: string;
  disabled?: boolean;
};

type TeamPrimaryStaffSelectProps = {
  isGame?: boolean;
  selectedHomeAwayTeamId?: string;
  selectedSubProgramId?: string;
  teams: TeamWithProps[];
  homeAway: 'home' | 'away';
};

export interface TeamWithProps extends Team {
  id: number;
  name: string;
  programId: number;
  teamRepresentative: TeamRepresentative;
  primaryStaff?: PrimaryStaff;
  external: boolean;
}

export function formatDataForHomeAwayTeamSelect({
  teams,
  isGame = false,
  homeAway,
  selectedSubProgramId,
  selectedHomeAwayTeamId,
}: TeamPrimaryStaffSelectProps) {
  if (selectedSubProgramId) {
    //filter teams for that subprogram
    const teamProgramId = teams.find(
      (team) => team.programId === Number(selectedSubProgramId)
    )?.programId;
    teams = teams.filter((team) => team.programId === teamProgramId);
  } else if (selectedHomeAwayTeamId) {
    //filter teams in the same subprogram
    const teamProgramId = teams.find(
      (team) => team.id === Number(selectedHomeAwayTeamId)
    )?.programId;
    teams = teams.filter((team) => team.programId === teamProgramId);
  }

  //do not display external teams in dropdown
  teams = teams.filter((team) => !team.external);

  const teamSelect: TeamPrimaryStaffSelectOptions[] = teams
    .map((team) => {
      return {
        label: team.name,
        value: team.id.toString(),
        primaryStaffName: team.primaryStaff?.name,
      };
    })
    .sort(
      (
        a: { label: string; value: string },
        b: { label: string; value: string }
      ) => {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      }
    );

  let homeAwayTeamOptions: TeamPrimaryStaffSelectOptions[] = [
    ...JSON.parse(JSON.stringify(teamSelect)),
  ];

  if (isGame) {
    homeAwayTeamOptions.unshift({
      label: 'TBD',
      value: homeAway === 'home' ? '-1' : '-2',
    });

    // Disable the selected home team in the away team options
    if (selectedHomeAwayTeamId) {
      homeAwayTeamOptions = homeAwayTeamOptions.map((team) => {
        return team.value === selectedHomeAwayTeamId
          ? { ...team, disabled: true }
          : team;
      });
    }
  }

  return homeAwayTeamOptions;
}

export function formatDataForExternalTeams({
  teams,
  selectedSubProgramId,
}: {
  teams: TeamWithProps[];
  selectedSubProgramId?: string;
}) {
  if (selectedSubProgramId) {
    //filter teams for that subprogram
    const teamProgramId = teams.find(
      (team) => team.programId === Number(selectedSubProgramId)
    )?.programId;
    teams = teams.filter((team) => team.programId === teamProgramId);
  }

  //filter out external teams
  teams = teams.filter((team) => team.external);

  const teamSelect: TeamPrimaryStaffSelectOptions[] = teams
    .map((team) => {
      return {
        label: team.name,
        value: team.id.toString(),
        primaryStaffName: team.primaryStaff?.name,
      };
    })
    .sort(
      (
        a: { label: string; value: string },
        b: { label: string; value: string }
      ) => {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      }
    );

  let homeAwayExternalTeamOptions: TeamPrimaryStaffSelectOptions[] = [
    ...JSON.parse(JSON.stringify(teamSelect)),
  ];

  return homeAwayExternalTeamOptions;
}
/** */

// Form Validation Schema
export const creativeActivitySchema = yup
  .object()
  .shape(
    {
      activityType: yup.string().required('Activity Type must be specified'),
      team: yup.string().required('Team must be specified'),
      frequency: yup.string().required('Frequency must be specified'),
      gameType: yup
        .string()
        .when(['activityType'], ([activityType], schema: yup.StringSchema) => {
          if (isGameActivity(activityType)) {
            return schema.required('Game type must be specified');
          }

          return schema.optional();
        }),
      awayTeam: yup
        .string()
        .when(['activityType'], ([activityType], schema: yup.StringSchema) => {
          if (isGameActivity(activityType)) {
            return schema.required('Team must be specified');
          }

          return schema.optional();
        }),
      externalTeam: yup
        .string()
        .when(
          ['team', 'awayTeam'],
          ([team, awayTeam], schema: yup.StringSchema) => {
            if (team === 'external' || awayTeam === 'external') {
              return schema.required('Team must be specified');
            }

            return schema.optional();
          }
        ),
      subProgram: yup
        .string()
        .when(['activityType'], ([activityType], schema: yup.StringSchema) => {
          if (isGameActivity(activityType)) {
            return schema.required('Sub-program must be specified');
          }

          return schema.optional();
        }),
      subLocation: yup
        .string()
        .when(
          'hasSubLocations',
          ([hasSubLocations], schema: yup.StringSchema) => {
            return hasSubLocations === true
              ? schema.required('Sub-location must be specified')
              : schema.optional();
          }
        ),
      arrival: yup
        .string()
        .test(
          'arrivalTime-before-startTime',
          'Arrival Time must come before Start Time',
          function () {
            let { arrival, startTime } = this.parent;

            const start = LuxonDateTime.fromISO(startTime);
            const arrivalTime = LuxonDateTime.fromISO(arrival);

            return arrival && startTime ? arrivalTime < start : true;
          }
        ),
      endTime: yup
        .string()
        .when(
          ['allDay', 'frequency', 'location', 'startDate', 'startTime'],
          (
            [allDay, frequency, location, startDate, startTime],
            schema: yup.StringSchema
          ) => {
            if (
              !allDay &&
              ((frequency && frequency !== 'does_not_repeat') ||
                location ||
                startDate ||
                startTime)
            ) {
              return schema.required('End Time must be specified');
            }
            return schema.optional();
          }
        )
        .test('endTime-after-startTime', ' ', function () {
          let { endTime, startTime } = this.parent;

          return startTimeBeforeEndTime(startTime, endTime);
        })
        .test(
          'duration-requirements',
          'Duration must be at least 15 minutes',
          function () {
            const { allDay, endTime, startTime } = this.parent;
            if (!allDay && endTime && startTime) {
              const end =
                endTime === '00:00'
                  ? LuxonDateTime.fromISO('24:00')
                  : LuxonDateTime.fromISO(endTime);
              const start = LuxonDateTime.fromISO(startTime);
              const duration = end.diff(start, 'minutes').toObject().minutes;
              if (duration) {
                // duration max is 23hrs & 59 minutes (1439 minutes)
                return duration >= 15 && duration <= 1439;
              }
            }
            return true;
          }
        ),
      location: yup
        .string()
        .when(
          ['allDay', 'endTime', 'frequency', 'startDate', 'startTime'],
          (
            [allDay, endTime, frequency, startDate, startTime],
            schema: yup.StringSchema
          ) => {
            if (
              allDay ||
              endTime ||
              startDate ||
              startTime ||
              (frequency && frequency !== 'does_not_repeat')
            ) {
              return schema.required('Location must be specified');
            }
            return schema.optional();
          }
        ),
      startDate: yup
        .string()
        .when(
          ['allDay', 'endTime', 'frequency', 'location', 'startTime'],
          (
            [allDay, endTime, frequency, location, startTime],
            schema: yup.StringSchema
          ) => {
            if (
              allDay ||
              (frequency && frequency !== 'does_not_repeat') ||
              endTime ||
              location ||
              startTime
            ) {
              return schema.required('Start Date must be specified');
            }
            return schema.optional();
          }
        )
        .test(
          'startDate-before-endDate',
          'Start Date must be before End Date',
          function () {
            let { allDay, startDate, endDate, frequency } = this.parent;

            if (!allDay && frequency !== 'does_not_repeat') {
              return startDateBeforeEndDate(startDate, endDate);
            }
            return true;
          }
        )
        .test(
          'startDate-less-than-year-in-past',
          'Start Date must be less than a year prior to today',
          function () {
            let { startDate } = this.parent;

            const today = LuxonDateTime.now();
            const start = LuxonDateTime.fromISO(startDate);

            const range = today.diff(start, 'days').toObject().days;

            return range === undefined || range < 366;
          }
        ),
      endDate: yup
        .string()
        .when(
          ['allDay', 'frequency'],
          ([allDay, frequency], schema: yup.StringSchema) => {
            if (allDay || (frequency && frequency !== 'does_not_repeat')) {
              return schema.required('End Date must be specified');
            }
            return schema.optional();
          }
        )
        .test(
          'endDate-equalOrafter-startDate',
          (value, { createError, path, parent }) => {
            const { allDay, startDate, endDate, frequency } = parent;

            if (allDay) {
              const duration = durationBetweenTwoDates(
                startDate,
                endDate,
                frequency
              );

              if (duration && duration < 1) {
                return createError({
                  path,
                  message: 'End Date must be after or equal to Start Date',
                });
              }
            }

            return true;
          }
        )
        //Daily minimum limitation: 1 day
        .test(
          'endDate-daily-min-limitation',
          'End Date must be at least 1 day after Start Date',
          function () {
            let { startDate, endDate, frequency } = this.parent;

            if (frequency === ActivityRecurrenceFrequencyUnitEnum.Day) {
              const duration = durationBetweenTwoDates(
                startDate,
                endDate,
                ActivityRecurrenceFrequencyUnitEnum.Day
              );
              return duration === undefined || duration >= 1;
            }

            return true;
          }
        )
        //Daily maximum limitation: 365 days
        .test(
          'endDate-daily-max-limitation',
          'End Date cannot exceed 365 days after Start Date',
          function () {
            let { allDay, startDate, endDate, frequency } = this.parent;

            if (allDay) {
              const end = LuxonDateTime.fromISO(endDate).endOf('day');
              const start = LuxonDateTime.fromISO(startDate).startOf('day');
              const duration = end.diff(start, 'day').toObject().days;

              return duration === undefined || duration <= 365;
            }

            if (frequency === ActivityRecurrenceFrequencyUnitEnum.Day) {
              const duration = durationBetweenTwoDates(
                startDate,
                endDate,
                ActivityRecurrenceFrequencyUnitEnum.Day
              );

              return duration === undefined || duration < 366;
            }

            return true;
          }
        )
        //Weekly minimum limitation: 1 week
        .test(
          'endDate-week-min-limitation',
          'End Date must be at least 1 week after Start Date',
          function () {
            let { startDate, endDate, frequency } = this.parent;

            if (frequency === ActivityRecurrenceFrequencyUnitEnum.Week) {
              const duration = durationBetweenTwoDates(
                startDate,
                endDate,
                ActivityRecurrenceFrequencyUnitEnum.Week
              );

              return duration === undefined || duration >= 1;
            }

            return true;
          }
        )
        //Weekly maximum limitation: 52 weeks
        .test(
          'endDate-week-max-limitation',
          'End Date cannot exceed 52 weeks after Start Date',
          function () {
            let { startDate, endDate, frequency } = this.parent;

            if (frequency === ActivityRecurrenceFrequencyUnitEnum.Week) {
              const duration = durationBetweenTwoDates(
                startDate,
                endDate,
                ActivityRecurrenceFrequencyUnitEnum.Week
              );

              return duration === undefined || duration < 53;
            }

            return true;
          }
        )
        //Monthly minimum limitation: 1 month
        .test(
          'endDate-month-min-limitation',
          'End Date must be at least 1 month after Start Date',
          function () {
            let { startDate, endDate, frequency } = this.parent;

            if (frequency === ActivityRecurrenceFrequencyUnitEnum.Month) {
              const duration = durationBetweenTwoDates(
                startDate,
                endDate,
                ActivityRecurrenceFrequencyUnitEnum.Month
              );

              return duration === undefined || duration >= 1;
            }

            return true;
          }
        )
        //Monthly maximum limitation: 12 months
        .test(
          'endDate-month-max-limitation',
          'End Date cannot exceed 12 months after Start Date',
          function () {
            let { startDate, endDate, frequency } = this.parent;

            if (frequency === ActivityRecurrenceFrequencyUnitEnum.Month) {
              const duration = durationBetweenTwoDates(
                startDate,
                endDate,
                ActivityRecurrenceFrequencyUnitEnum.Month
              );
              return duration === undefined || Math.ceil(duration) <= 12;
            }

            return true;
          }
        ),
      startTime: yup
        .string()
        .when(
          ['allDay', 'endTime', 'frequency', 'location', 'startDate'],
          (
            [allDay, endTime, frequency, location, startDate],
            schema: yup.StringSchema
          ) => {
            if (
              !allDay &&
              ((frequency && frequency !== 'does_not_repeat') ||
                endTime ||
                location ||
                startDate)
            ) {
              return schema.required('Start Time must be specified');
            }
            return schema.optional();
          }
        )
        .test(
          'startTime-before-endTime',
          'Start Time must come before End Time',
          function () {
            let { endTime, startTime } = this.parent;

            return startTimeBeforeEndTime(startTime, endTime);
          }
        ),
    },
    [
      /** Dependencies */
      //activityType: ['gameType']
      ['activityType', 'gameType'],
      ['activityType', 'awayTeam'],

      // allDay: ['location','startDate']
      ['allDay', 'location'],
      ['allDay', 'startDate'],

      // location: ['endTime', 'frequency', 'startDate', 'startTime']
      ['location', 'endTime'],
      ['location', 'frequency'],
      ['location', 'startDate'],
      ['location', 'startTime'],

      //endTime: ['frequency', 'startDate', 'startTime'],
      ['endTime', 'frequency'],
      ['endTime', 'startDate'],
      ['endTime', 'startTime'],

      //startDate:  ['frequency',  'startTime'],
      ['startDate', 'frequency'],
      ['startDate', 'startTime'],

      // endDate: ['frequency', 'startDate'],
      ['endDate', 'frequency'],
      ['endDate', 'startDate'],

      //startTime: [ 'frequency']
      ['startTime', 'frequency'],
    ]
  )
  .required();

/**
 * @param startDate string
 * @param endDate string
 * @param frequency Frequency
 * @returns duration in days between two given dates
 */
export function durationBetweenTwoDates(
  startDate: string,
  endDate: string,
  frequency: Frequency | ActivityRecurrenceFrequencyUnitEnum
) {
  const end = LuxonDateTime.fromISO(endDate).startOf('day');
  const start = LuxonDateTime.fromISO(startDate).startOf('day');

  if (
    frequency === ActivityRecurrenceFrequencyUnitEnum.Day ||
    frequency === 'does_not_repeat'
  ) {
    return end.diff(start, 'day').toObject().days;
  } else if (frequency === ActivityRecurrenceFrequencyUnitEnum.Week) {
    let duration = end.diff(start, 'week').toObject().weeks;
    return duration ? Math.floor(duration) : undefined;
  } else if (frequency === ActivityRecurrenceFrequencyUnitEnum.Month) {
    let duration = end.diff(start, 'month').toObject().months;
    return duration ?? undefined;
  }
}

function startDateBeforeEndDate(startDate: string, endDate: string) {
  const duration = durationBetweenTwoDates(
    startDate,
    endDate,
    ActivityRecurrenceFrequencyUnitEnum.Day
  );

  return duration !== undefined ? duration > 0 : true;
}

function startTimeBeforeEndTime(startTime: string, endTime: string) {
  if (endTime && endTime === '00:00') {
    endTime = '24:00';
  }

  return endTime && startTime ? endTime > startTime : true;
}

/**
 *
 * @returns formatted data for Update & Create ActivityRequestBody
 * @param data
 */
export function getUpdateActivityRequestBody(
  data: ActivityFormData,
  teams?: TeamPatch[]
): UpdateActivityRequestBody {
  let startDate: DateTime | undefined;
  if (data.startDate && data.startTime) {
    startDate = {
      date: data.startDate,
      time: data.startTime,
    };
  }

  let arrivalTime: DateTime | undefined;
  if (data.startDate && data.arrival) {
    arrivalTime = {
      date: data.startDate,
      time: data.arrival,
    };
  }

  let endTime: DateTime | undefined;
  if (data.startDate && data.endTime) {
    endTime = {
      //placeholder until recurring activity is set up
      date: data.startDate,
      time: data.endTime,
    };
  }

  const updateRequestData: UpdateActivityRequestBody = {
    locationId: data.location ? parseInt(data.location, 10) : undefined,
    subLocationId: data.subLocation
      ? parseInt(data.subLocation, 10)
      : undefined,
    start: startDate,
    state:
      data.location && startDate && endTime
        ? ActivityState.Scheduled
        : ActivityState.Unscheduled,
    arrival: arrivalTime ?? null,
    end: endTime,
    notes: data.notes,
    title: data.title,
    teams: teams,
  };

  return updateRequestData;
}

/* Type-guard for game activity */
function isGameFormData(data: ActivityFormData): data is ActivityGame {
  const activityType = data.activityType;
  return (
    activityType === ActivityType.GameConsolation ||
    activityType === ActivityType.GameFriendly ||
    activityType === ActivityType.GameLeague ||
    activityType === ActivityType.GamePlayoff ||
    activityType === ActivityType.GameScrimmage ||
    activityType === ActivityType.GameSeason ||
    activityType === ActivityType.GameTournament ||
    activityType === ActivityType.GameFinal
  );
}

/* Type-guard for recurring game activity */
function isRecurringGameActivity(
  data: ActivityFormData
): data is RecurringGameActivity {
  return !!data.endDate && !!data.awayTeam && !!data.gameType;
}

export function getCreateActivityRequestBody(
  data: ActivityFormData,
  siteId: number,
  programId: number
): CreateActivityRequestBody {
  const activityRequestData = getUpdateActivityRequestBody(data);
  const subProgramId = parseInt(data.subProgram, 10);
  let arrivalTime: DateTime | undefined;

  if (data.startDate && data.arrival) {
    arrivalTime = {
      date: data.startDate,
      time: data.arrival,
    };
  }

  const teams: ActivityTeam[] = [
    {
      teamId: parseInt(data.team, 10),
      programId: subProgramId,
      activityId: 0,
    },
  ];

  if (isGameFormData(data)) {
    teams[0].homeAway = HomeAway.Home;

    teams.push({
      teamId: parseInt(data.awayTeam, 10),
      programId: subProgramId,
      activityId: 0,
      homeAway: HomeAway.Away,
    });
  }

  return {
    ...activityRequestData,
    arrival: arrivalTime,
    programId,
    siteId,
    //TODO: Update to handle multi select teams
    teams: teams,
    type: data.activityType as ActivityType,
  };
}

export function getAllDayActivityRequestBody(
  data: ActivityFormAllDayActivity,
  teams?: TeamPatch[]
) {
  const allDayRequest = {
    title: data.title,
    notes: data.notes,
    date: data.startDate,
    endDate: data.endDate,
    locationId: parseInt(data.location, 10),
    subLocationId: data.subLocation
      ? parseInt(data.subLocation, 10)
      : undefined,
    teams: teams,
  };

  return allDayRequest;
}

export function getHomeAwayTeamsId(teams: ActivityTeam[]) {
  const homeTeam =
    teams.find((team) => team.homeAway === 'home')?.teamId || teams[0].teamId;
  const awayTeam =
    teams.find((team) => team.homeAway === 'away')?.teamId || teams[1]?.teamId;

  return { homeTeam, awayTeam };
}

export function formHasBeenChanged(
  formValues: ActivityFormData,
  activity: Activity
) {
  const {
    arrival,
    endDate,
    endTime,
    location,
    notes,
    startDate,
    startTime,
    subLocation,
    subProgram,
    team,
    awayTeam,
    gameType,
    title,
  } = formValues;

  if (gameType !== activity.type) {
    return true;
  }

  if (arrival !== activity.arrival?.time) {
    return true;
  }

  if (endDate !== activity.end?.date) {
    return true;
  }

  if (endTime !== activity.end?.time) {
    return true;
  }

  if (location && parseInt(location, 10) !== activity.locationId) {
    return true;
  }

  if (notes !== activity.notes) {
    return true;
  }

  if (startDate !== activity.start?.date) {
    return true;
  }

  if (startTime !== activity.start?.time) {
    return true;
  }

  if (subLocation && parseInt(subLocation, 10) !== activity.subLocationId) {
    return true;
  }

  if (
    subProgram &&
    activity.teams.length > 0 &&
    parseInt(subProgram, 10) !== activity.teams[0].programId
  ) {
    return true;
  }

  if (
    team &&
    parseInt(team, 10) !== getHomeAwayTeamsId(activity.teams).homeTeam
  ) {
    return true;
  }

  if (
    awayTeam &&
    parseInt(awayTeam, 10) !== getHomeAwayTeamsId(activity.teams).awayTeam
  ) {
    return true;
  }

  if (title !== activity.title) {
    return true;
  }

  return false;
}

/**
 *
 * @param data ActivityFormData
 * @param programId number
 * @param siteId number
 * @returns formatted data for CreateRecurringActivityRequestBody
 */

export function processRecurringSetActivity(
  data: ActivityFormData,
  teams?: TeamPatch[]
) {
  const updateRecurringSetData: UpdateRecurringActivityRequestBody = {
    arrivalTime: data.arrival ?? undefined,
    endTime: data.endTime ?? undefined,
    locationId: data.location ? parseInt(data.location, 10) : undefined,
    notes: data.notes ?? undefined,
    startTime: data.startTime ?? undefined,
    subLocationId: data.subLocation
      ? parseInt(data.subLocation, 10)
      : undefined,
    title: data.title ?? undefined,
  };

  if (teams && teams.length) {
    updateRecurringSetData.teams = teams;
  }

  //remove undefined values
  const updatedValues = Object.fromEntries(
    Object.entries(updateRecurringSetData).filter(
      ([_, value]) => value !== undefined
    )
  );

  return updatedValues;
}

export function processCreateRecurringActivitiesData(
  data: RecurringActivity | RecurringGameActivity,
  programId: number,
  siteId: number
) {
  const dayOfWeek = formatDayOfWeek(data.startDate);
  const dayOfMonth = LuxonDateTime.fromISO(data.startDate).day;

  const teams: ActivityTeam[] = [
    {
      teamId: parseInt(data.team, 10),
      programId: parseInt(data.subProgram, 10),
      activityId: 0,
    },
  ];

  if (isRecurringGameActivity(data)) {
    teams[0].homeAway = HomeAway.Home;

    teams.push({
      teamId: parseInt(data.awayTeam, 10),
      programId: parseInt(data.subProgram, 10),
      activityId: 0,
      homeAway: HomeAway.Away,
    });
  }

  // need to not just send in teams.
  const requestData: CreateRecurringActivityRequestBody = {
    programId: programId,
    siteId: siteId,
    notes: data.notes,
    state: ActivityState.Scheduled,
    title: data.title,
    type: data.activityType as ActivityType,
    recurrence: {
      arrivalTime: data.arrival,
      startDate: data.startDate,
      startTime: data.startTime,
      endDate: data.endDate,
      endTime: data.endTime,
      frequencyUnit: data.frequency,
      dayOfWeek: dayOfWeek,
      daysOfWeek: [dayOfWeek],
      dayOfMonth: dayOfMonth,
    },
    locationId: parseInt(data.location, 10),
    subLocationId: data.subLocation
      ? parseInt(data.subLocation, 10)
      : undefined,
  };

  if (teams && teams.length) {
    requestData.teams = teams;
  }

  return requestData;
}

function formatDayOfWeek(date: string): DayOfWeek {
  const weekDay = LuxonDateTime.fromISO(date).weekday;

  switch (weekDay) {
    case 1:
      return DayOfWeek.Monday;
    case 2:
      return DayOfWeek.Tuesday;
    case 3:
      return DayOfWeek.Wednesday;
    case 4:
      return DayOfWeek.Thursday;
    case 5:
      return DayOfWeek.Friday;
    case 6:
      return DayOfWeek.Saturday;
    case 7:
      return DayOfWeek.Sunday;
    default:
      return DayOfWeek.Sunday;
  }
}

export function isTeamTBD(teamId: string | number) {
  return Number(teamId) < 0;
}
