"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createValidationSchema = exports.FILE_FIELD_REQUIRED_ERROR = exports.FIELD_REQUIRED_ERROR = void 0;
var yup = require("yup");
exports.FIELD_REQUIRED_ERROR = 'This field is required.';
exports.FILE_FIELD_REQUIRED_ERROR = 'Please upload required documentation.';
var createValidationSchema = function (id) {
    var _a;
    return yup.object((_a = {},
        _a[id] = yup.array().of(yup.lazy(function (field) {
            var value;
            var type = field.type;
            switch (type) {
                case 'NUMERIC':
                    value = yup
                        .number()
                        .nullable()
                        .transform(function (_, val) { return (val === 0 || val ? Number(val) : null); });
                    break;
                case 'PICK_LIST':
                    value = yup
                        .number()
                        .transform(function (val, originalValue) {
                        return originalValue === '' ? undefined : val;
                    });
                    break;
                case 'FILE_UPLOAD':
                    value = yup
                        .object({
                        name: yup.string(),
                        file: yup.mixed().nullable(),
                        uuid: yup.string().optional(),
                    })
                        .default(undefined);
                    break;
                case 'MULTIPLE_CHECKBOXES':
                    value = yup.array().of(yup.number());
                    break;
                default:
                    value = yup.string().trim();
                    break;
            }
            value =
                field.usageLevel === 'REQUIRED'
                    ? value.required(field.type === 'FILE_UPLOAD'
                        ? exports.FILE_FIELD_REQUIRED_ERROR
                        : exports.FIELD_REQUIRED_ERROR)
                    : value.optional();
            var properties = {
                propertyDefinitionId: yup.number(),
                programId: yup.number().optional(),
                type: yup.string(),
                name: yup.string(),
                usageLevel: yup.string(),
                order: yup.number(),
                items: yup.array().optional(),
                value: value,
            };
            return yup.object(properties);
        })),
        _a));
};
exports.createValidationSchema = createValidationSchema;
