"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseSteppedRegistration = exports.RegistrationWithSteps = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var services_1 = require("@la/services");
var Registration_1 = require("../Registration");
var TeamRegistrationContext_1 = require("../TeamRegistration/TeamRegistrationContext");
var RegistrationWithSteps = function (_a) {
    var includeRegistrationTriageFix = _a.includeRegistrationTriageFix;
    return ((0, jsx_runtime_1.jsx)(services_1.Provider, __assign({ store: services_1.store }, { children: (0, jsx_runtime_1.jsx)(TeamRegistrationContext_1.TeamRegistrationProvider, { children: (0, jsx_runtime_1.jsx)(exports.BaseSteppedRegistration, { includeRegistrationTriageFix: includeRegistrationTriageFix }) }) })));
};
exports.RegistrationWithSteps = RegistrationWithSteps;
var BaseSteppedRegistration = function (_a) {
    var includeRegistrationTriageFix = _a.includeRegistrationTriageFix;
    return ((0, jsx_runtime_1.jsx)(Registration_1.Registration, { isMC: true, includeRegistrationTriageFix: includeRegistrationTriageFix }));
};
exports.BaseSteppedRegistration = BaseSteppedRegistration;
