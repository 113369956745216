import { RadioGroup, TextInput, Typography } from '@la/ds-ui-components';
import { ChildYouthAthleteAccess } from '../YouthAthleteAccess.types';
import * as S from '../EditMobileAppAccess.styles';

interface YouthAthleteSectionProps
  extends Omit<ChildYouthAthleteAccess, 'siteId' | 'youthAthleteId'> {
  emailReadOnly?: boolean;
  emailErrorMsg?: string;
  onChangeEmail: (value: string | undefined) => void;
  onChangePermission: (value: boolean) => void;
}

export default function YouthAthleteSection({
  firstName,
  userId,
  email,
  hasYouthAthletePermission,
  emailReadOnly,
  emailErrorMsg,
  onChangeEmail,
  onChangePermission,
}: YouthAthleteSectionProps) {
  return (
    <S.MobileAppAccessSection id={`user-${userId}`}>
      <Typography variant="headline" size="medium">
        {firstName}'s mobile app access settings
      </Typography>
      <Typography variant="ui" size="large" weight="bold">
        This is the email address tied to your child's profile. This will be
        used for the child's mobile app login.
      </Typography>
      <S.MobileAppAccessTextInputContainer>
        <TextInput
          onChange={(e) => {
            onChangeEmail(e.target.value);
          }}
          hasError={!!emailErrorMsg}
          errorMessage={emailErrorMsg}
          id={userId + '-email'}
          label="Child's Email"
          required={!emailReadOnly && hasYouthAthletePermission}
          readOnly={emailReadOnly}
          value={email}
          tooltipText="To change your child's email, use the family dashboard"
        />
      </S.MobileAppAccessTextInputContainer>
      <div>
        <Typography variant="ui" size="medium" weight="bold">
          I give permission for this child to join the mobile app
        </Typography>
        <RadioGroup
          onValueChange={(value) => {
            onChangePermission(value === 'yes');
          }}
          ariaLabel="I give permission for this child to join the mobile app"
          options={[
            {
              id: userId + '-permission-yes',
              label: 'Yes',
              value: 'yes',
            },
            {
              id: userId + 'permission-no',
              label: 'No',
              value: 'no',
            },
          ]}
          defaultValue={hasYouthAthletePermission ? 'yes' : 'no'}
          orientation="horizontal"
        />
      </div>
    </S.MobileAppAccessSection>
  );
}
