import styled from 'styled-components/macro';
import { ThemedComponent, Typography } from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';
import Page from '../Shell/Page/Page';
import { Registrant } from './RosterTable';

export const RosterManagementPage = styled(Page)``;

export const MinorColumn = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;

  ${media.TabletPortraitUp`
    grid-column-start: 1;
    grid-column-end: 9;
  `}

  ${media.TabletLandscapeUp`
    grid-column-start: 1;
    grid-column-end: 5;
  `}
`;
export const MajorColumn = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;

  ${media.TabletPortraitUp`
    grid-column-start: 1;
    grid-column-end: 9;
  `}

  ${media.TabletLandscapeUp`
    grid-column-start: 5;
    grid-column-end: 17;
  `}
`;

// Roster Utilities
export const RosterUtilities = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 24px;
  border-top: 1px solid var(--blue-grey-200);

  ${media.TabletPortraitUp`
    padding-top: 16px;
  `}

  ${media.TabletLandscapeUp`
    padding-top: 0;
    border-top: none;
    border-bottom: 1px solid var(--blue-grey-200);
  `}

  svg {
    stroke: var(--secondary-600);
  }
`;

// Roster Section
export const RosterSection = styled.div`
  padding: 24px 0 0 0;
`;

export const SectionHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`;

export const HeaderButtonsSection = styled.div`
  display: flex;
  gap: 8px;
`;

// Roster Table
export const RosterTable = styled.div`
  width: 100%;
  margin: 24px 0;
  border: 1px solid var(--primary-300);
  border-radius: 4px;
  overflow: hidden;
`;

export const EmptyRosterNotice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: 24px 0;
  padding: 36px 24px 40px;
  border: 1px solid var(--blue-grey-400);
  border-radius: 4px;
`;

export const RosterTableHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  height: 36px;
  padding: 0 24px;
  background-color: var(--primary-35);
  border-bottom: 1px solid var(--primary-300);

  p {
    color: var(--primary-700);
  }
`;

export const RosterTableBody = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

export const RosterTableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  min-height: 44px;
  padding: 0 16px;
  border-bottom: 1px solid var(--primary-100);

  ${media.TabletPortraitUp`
    gap: 24px;
    min-height: 80px;
    padding: 0 24px;  
  `}

  &:last-of-type {
    border-bottom: none;
  }
`;

export const RosterTableColumn_Name = styled.div`
  flex-basis: 140px;

  ${media.TabletPortraitUp`
    flex-basis: 272px;
  `}
  ${media.TabletLandscapeUp`
    flex-basis: 300px;
  `}
  ${media.DesktopUp`
    flex-basis: 350px;
  `}
`;

export const RosterTableColumn_EditDelete = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-basis: 160px;
  justify-content: flex-end;

  ${media.TabletPortraitUp`
    min-width: 180px;
  `}
`;

export const RosterTableColumn_Avatar = styled.div`
  display: none;

  ${media.TabletPortraitUp`
    display: block;
    flex-basis: 56px;
  `}
`;

export const RosterAvatar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 28px;
  background-color: var(--blue-grey-50);
  overflow: hidden;

  > h4 {
    color: var(--blue-grey-400);
    text-transform: uppercase;
  }
`;

type RosterStatusBadgeProps = ThemedComponent<{
  $registrationStatus: Registrant['registrationStatus'];
}>;

export const RosterStatusBadge = styled.span<RosterStatusBadgeProps>(
  ({ $registrationStatus, theme }: RosterStatusBadgeProps) => {
    let backgroundColor,
      color = theme.foundational.blueGrey[900];
    if ($registrationStatus === 'SPOT_RESERVED') {
      backgroundColor = theme.foundational.green[600];
      color = theme.foundational.white;
    } else if ($registrationStatus === 'PENDING_INVITE') {
      backgroundColor = theme.foundational.blueGrey[50];
    } else {
      backgroundColor = theme.foundational.green[100];
    }

    return {
      display: 'flex',
      flex: 1,

      '> p': {
        display: 'inline-block',
        height: '18px',
        padding: '1px 8px',
        borderRadius: '9px',
        backgroundColor,
        color,
        textTransform: 'capitalize',
        flexBasis: '56px',
        [theme.mediaQueries.mobile]: {
          height: '24px',
          padding: '0 10px',
          borderRadius: '12px',
        },
      },
    };
  }
);

export const DeleteButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;

  svg {
    stroke: var(--secondary-600);
  }

  &:disabled {
    cursor: not-allowed;

    svg {
      stroke: var(--blue-grey-200);
    }
  }
`;

// Copy Player Invite Link Modal
export const CopyPlayerLinkFields = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
  margin-top: 24px;

  > div:first-of-type {
    flex: 1;
  }
`;

// Copy Staff Invite Link Modal
export const CopyStaffLinkFields = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 24px;

  //The select menu
  > div:first-of-type {
    label {
      margin-bottom: 6px;
    }

    button {
      margin: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 1;

      &:focus-within {
        border-radius: 4px;
      }
    }
  }

  //The text input field
  > div:nth-child(2) {
    flex: 1;
    margin: 0 8px 0 -4px;

    > div div {
      input {
        padding: 0 0 0 4px;
      }

      &:focus-within {
        z-index: 2;
      }
    }
  }
`;

// View Different Team Modal
export const ViewDifferentTeamModalSubhead = styled(Typography)`
  margin: 0 0 24px 0;
`;

export const CurrentlySelectedBadge = styled.div`
  display: inline-block;
  height: 18px;
  margin: 0 0 8px 0;
  padding: 0 4px;
  border-radius: 9px;
  font-family: 'DM Sans', 'Segoe UI', Tahoma, sans-serif;
  font-size: var(--ui-size-xtra-small);
  font-weight: 400;
  text-align: center;
  line-height: 18px;
  background-color: var(--blue-grey-50);
`;

export const DifferentTeams = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0 0 0;
  padding: 16px 0 0 0;
  border-top: 1px solid var(--blue-grey-200);
`;

export const TeamInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  p {
    color: var(--blue-grey-900);
  }

  a {
    color: var(--secondary-600);
  }
`;

// CSV Download Modal
export const MessageBox = styled.div<{ $messageType: 'error' | 'success' }>`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 16px;
  background-color: ${({ $messageType }) =>
    $messageType === 'error' ? 'var(--red-25)' : 'var(--green-25)'};
  border: 1px solid;
  border-color: ${({ $messageType }) =>
    $messageType === 'error' ? 'var(--red-500)' : 'var(--green-500)'};
  border-radius: 8px;
`;

export const MessageTitle = styled.span`
  font: var(--ui-medium-bold);
`;

export const MessageBody = styled.span`
  margin: 0;
  padding: 0;
  font: var(--ui-medium-regular);
`;

export const IconSection = styled.div`
  height: 24px;
`;

export const TextSection = styled.div`
  padding-top: 1px;
`;
