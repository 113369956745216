import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';
import Page from 'domains/Shell/Page/Page';

export const EditMobileAppAccessPage = styled(Page)``;

export const MobileAppAccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2em 2.5em;
`;

export const MobileAppAccessHeadingContainer = styled.div`
  padding-bottom: 2em;
`;

export const MobileAppAccessSection = styled.div`
  padding: 1.75rem 0;
  border-bottom: 1px solid var(--blue-grey-200);

  &:first-child {
    border-top: 1px solid var(--blue-grey-200);
  }

  & > * {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const MobileAppAccessTextInputContainer = styled.div`
  width: 100%;

  ${media.TabletPortraitUp`
    width: 50%;
  `};
`;

export const MobileAppAccessCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 1em;
`;

export const MobileAppAccessNoticeContainer = styled.div`
  display: flex;
  margin-top: 1em;
`;

export const MobileAppAccessSubmitBtnsContainer = styled.div`
  margin-top: 3em;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${media.TabletPortraitUp`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const MobileAppAccessPrimaryBtnContainer = styled.div`
  width: 100%;

  ${media.TabletPortraitUp`   
    width: fit-content;
  `}
`;

export const MobileAppAccessConfirmationLinkContainer = styled.div`
  margin-top: 3em;
  display: flex;
  align-items: center;

  ${media.TabletPortraitUp`
      align-items: left;
    `};
`;
