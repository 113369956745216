import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Loader } from '@la/ds-ui-components';
import { useGetGroupAccountAthletes } from 'lib/apis/getGroupAccountYouthAthletes';
import setGroupAccountYouthAthletes from 'lib/apis/setGroupAccountYouthAthletes';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useAppSelector } from 'redux/store';
import Page from 'domains/Shell/Page/Page';
import EditMobileAppAccessConfirmation from './EditMobileAppAccessConfirmation/EditMobileAppAccessConfirmation';
import EditMobileAppAccessPage, {
  EditMobileAppAccessOnSubmitProps,
} from './EditMobileAppAccessPage/EditMobileAppAccessPage';

export default function EditMobileAppAccessWrapper() {
  const { siteName, siteId } = useAppSelector(getSiteIdentityData);
  const [searchParams] = useSearchParams();
  const groupAccountId = searchParams.get('groupAccountId');

  if (!siteId) {
    throw new Error('Cannot get site information');
  }

  if (!groupAccountId) {
    throw new Error('Missing groupAccountId query param');
  }

  const { youthAthleteData, setYouthAthleteData, youthAthleteResponseError } =
    useGetGroupAccountAthletes({
      groupAccountId: parseInt(groupAccountId),
      siteId: parseInt(siteId),
    });

  if (youthAthleteResponseError) {
    let msg: string =
      youthAthleteResponseError.data?.error || 'Unexpected error.';
    throw new Error(`Cannot load youth athlete data: ${msg}`);
  }

  const [isSubmitted, setSubmitted] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');

  const onSubmitHandler = ({
    originalData,
    newData,
  }: EditMobileAppAccessOnSubmitProps) => {
    setFormError('');

    setGroupAccountYouthAthletes({ originalData, newData })
      .then((data) => {
        setYouthAthleteData(data);
        setSubmitted(true);
      })
      .catch((err) => {
        setSubmitted(false);
        setFormError(err.message);
      });
  };

  if (youthAthleteData) {
    if (isSubmitted) {
      return (
        <EditMobileAppAccessConfirmation
          familyYouthAthleteAccess={youthAthleteData}
        />
      );
    } else {
      return (
        <EditMobileAppAccessPage
          familyYouthAthleteAccess={youthAthleteData}
          siteName={siteName}
          updateYouthAthleteFormValues={onSubmitHandler}
          error={formError}
        />
      );
    }
  }

  return (
    <Page>
      <Loader loading />
    </Page>
  );
}
